import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'

import AppToast from '../components/app/AppToast.vue'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(PrimeVue)
  nuxtApp.vueApp.use(ToastService)

  nuxtApp.vueApp.component('AppToast', AppToast)
})
