import { defineNuxtPlugin } from '#app'

import { AnalyticsService } from '../services/analytics'
import { RudderStackAnalyticAdapter } from '../services/analytics/rudderstack-adapter'
import { TestingAnalyticAdapter } from '../services/analytics/testing-adapter'
import debounce from 'lodash/debounce'

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig().public
  const { $http } = useNuxtApp()

  let analyticsService: AnalyticsService = {
    track: () => {},
    identify: () => {},
    page: () => {},
    client: undefined,
    trackElementClicked: () => {},
    trackFieldInput: () => {},
    trackFieldInputted: debounce(() => {}),
    trackFormSubmit: () => {},
  }

  const rudderstack = await import('rudder-sdk-js')

  const client =
    // @ts-ignore
    window.Cypress || config.appEnv === 'test'
      ? new TestingAnalyticAdapter($http)
      : new RudderStackAnalyticAdapter(rudderstack, {
          key: config.rudderstackWriteKey,
          url: config.rudderstackDataPlaneUrl,
        })

  analyticsService = new AnalyticsService(client)

  return {
    provide: {
      analytics: analyticsService,
    },
  }
})
