
import { defineNuxtPlugin, useRuntimeConfig } from '#imports';
import PrimeVue from 'primevue/config';

false
import passthrough from '/home/node/apps/claimant-portal-ui/assets/passthrough.js';

import customTheme from '/home/node/apps/claimant-portal-ui/assets/theme.js';


export default defineNuxtPlugin(({ vueApp }) => {
  const runtimeConfig = useRuntimeConfig();
  const config = runtimeConfig?.public?.primevue ?? {};
  const { usePrimeVue = true, options = {} } = config;
  const pt = { pt: passthrough };
  const theme = { theme: customTheme || options?.theme };

  usePrimeVue && vueApp.use(PrimeVue, { ...options, ...pt, ...theme });
  
  false
});
        