import { BrandingTheme } from '@torticity/types'

export const DEFAULT_BRANDING = {
  id: '1',
  subdomain: 'app',
  name: 'Torticity default',
  lawFirmId: '2',
  supportEmail: 'support@support.torticity.com',
  supportFaxNumber: '5614616127',
  sms_number: '5614487828',
  address1: '7700 Griffin Way',
  address2: 'Suite B',
  city: 'Willowbrook',
  state: 'IL',
  zipCode: '60527',
  pngLogoUrl:
    'https://app.torticity.com/_ipx/f_png/https://assets.torticity.com/emails/logos/torticity_logo.svg',
  svgLogoUrl: 'https://assets.torticity.com/emails/logos/torticity_logo.svg',
  faviconUrl: 'https://assets.torticity.com/emails/logos/torticity_logo.png',
  brandingExperience: 'cobrand',
  theme: BrandingTheme.VIOLET,
}
export const DEFAULT_THEME = BrandingTheme.VIOLET
