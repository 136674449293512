import type { RouterConfig } from '@nuxt/schema'
import { parse, stringify } from 'qs'

export default <RouterConfig>{
  parseQuery: parse,
  stringifyQuery: stringify,
  scrollBehavior() {
    return { top: -10 }
  },
}
