import validate from "/home/node/apps/claimant-portal-ui/node_modules/.pnpm/nuxt@3.5.3_ib6n3u26orgsw3ddu5c2ar5ipm/node_modules/nuxt/dist/pages/runtime/validate.js";
import analytics_45middleware_45global from "/home/node/apps/claimant-portal-ui/middleware/analytics-middleware.global.ts";
import brand_45enforcement_45cookie_45global from "/home/node/apps/claimant-portal-ui/middleware/brand-enforcement-cookie.global.ts";
import brand_45global from "/home/node/apps/claimant-portal-ui/middleware/brand.global.ts";
import auth from "/home/node/apps/claimant-portal-ui/node_modules/.pnpm/@nuxt-alt+auth@2.4.2_7wrxmcbljtnlxttqdjlzw3uro4/node_modules/@nuxt-alt/auth/dist/runtime/core/middleware.mjs";
export const globalMiddleware = [
  validate,
  analytics_45middleware_45global,
  brand_45enforcement_45cookie_45global,
  brand_45global,
  auth
]
export const namedMiddleware = {}