import { AnalyticAdapter, ApiObject } from '.'

export interface trackingEvent {
  path: string
  event?: string
  properties?: Partial<ApiObject>
  category?: string
  name?: string
}

declare global {
  interface Window {
    analyticEvents: trackingEvent[]
  }
}

if (window) {
  window.analyticEvents ||= []
}

export class TestingAnalyticAdapter implements AnalyticAdapter {
  private url = 'https://fake.dataplane.rudderstack.com'

  constructor(private client: any) {}

  init() {
    this.client = this.client.create()
    this.client.setBaseURL(this.url)
    return
  }

  track(event: string, properties?: Partial<ApiObject>) {
    this.client.post(`/v1/track`, { body: { event, properties } })

    this.record({ path: `/v1/track`, event, properties })
  }

  identify(userId: string, traits?: Partial<ApiObject>) {
    this.client.post(`/v1/identify`, { body: { userId, traits } })

    this.record({
      path: `/v1/identify`,
      event: 'identify',
      properties: { userId, traits },
    })
  }

  page(category?: string, name?: string, properties?: Partial<ApiObject>) {
    this.client.post(`/v1/page`, { body: { category, name, properties } })

    this.record({
      path: `/v1/page`,
      event: 'page',
      category,
      name,
      properties,
    })
  }

  record(event: trackingEvent) {
    if (window) {
      window.analyticEvents ||= []

      window.analyticEvents.push(event)
    }
  }
}
