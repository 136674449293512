<template>
  <PrimeToast
    pt:root:class="tw-md:px-2 tw-px-8"
    :position="mobileScreen ? 'top-center' : 'top-right'"
    :pt="{
      root: { class: 'tw-rounded-md' },
      messageText: { class: 'tw-gap-1' },
    }"
  >
    <template #container="{ message, closeCallback }">
      <div
        class="tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-p-4"
        :class="[
          'tw-flex tw-items-center tw-gap-4 tw-rounded-l-md tw-rounded-r-md tw-border-l-4 tw-px-4',
          { 'tw-border-rose-600': message.severity === 'error' },
          { 'tw-border-blue-600': message.severity === 'info' },
          { 'tw-border-emerald-600': message.severity === 'success' },
        ]"
        @click="closeCallback"
      >
        <div @click="closeCallback">
          <InfoIcon
            v-if="message.severity === 'info'"
            size="1.5rem"
            class="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-blue-600"
          />
          <SuccessIcon
            v-else-if="message.severity === 'success'"
            size="1.5rem"
            class="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-emerald-800"
          />
          <ErrorIcon
            v-else-if="message.severity === 'error'"
            size="1.5rem"
            class="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-rose-600"
          />
          <InfoIcon v-else class="tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-blue-600" />
        </div>
        <div>
          <div
            :class="[
              'tw-font-semibold',
              { 'tw-text-blue-800': message.severity === 'info' },
              { 'tw-text-rose-800': message.severity === 'error' },
              { 'tw-text-emerald-800': message.severity === 'success' },
            ]"
          >
            {{ message.summary }}
          </div>
          <div
            :class="[
              'tw-text-base tw-font-normal',
              { 'tw-text-blue-800': message.severity === 'info' },
              { 'tw-text-rose-800': message.severity === 'error' },
              { 'tw-text-emerald-800': message.severity === 'success' },
            ]"
          >
            {{ message.detail }}
          </div>
        </div>
        <div v-if="message.closable !== false" @click="closeCallback">
          <XMarkIcon
            size="1.5rem"
            class="tw-h-5 tw-w-5 tw-flex-shrink-0"
            :class="[
              { 'tw-text-blue-600': message.severity === 'info' },
              { 'tw-text-rose-600': message.severity === 'error' },
              { 'tw-text-emerald-600': message.severity === 'success' },
            ]"
          />
        </div>
      </div>
    </template>
  </PrimeToast>
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

import SuccessIcon from '~icons/fluent/checkmark-circle-20-filled'
import XMarkIcon from '~icons/fluent/dismiss-20-filled'
import InfoIcon from '~icons/fluent/info-48-filled'
import ErrorIcon from '~icons/mdi/close-circle'

const breakpoints = useBreakpoints(breakpointsTailwind)

const mobileScreen = breakpoints.smaller('md')
</script>
