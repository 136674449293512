export default defineNuxtPlugin(_ => {
  const { $auth } = useNuxtApp()
  const route = useRoute()
  const config = useRuntimeConfig().public

  if (config.appEnv !== 'test' || route.query.disableTestAuth === '1' || config.disableTestAuth) {
    return
  }

  $auth.setUser({
    id: '1',
    email: 'tech@torticity.com',
    firstName: 'Torticity',
    lastName: 'Tech',
    status: 'active',
    lead: {
      id: '1',
      claimantId: '1',
    },
    personId: '1',
  })
  $auth.$storage.setState('loggedIn', true)
})
