import { default as _91actionId_93t1PRQN0HNEMeta } from "/home/node/apps/claimant-portal-ui/pages/actions/[actionId].vue?macro=true";
import { default as indexEQsLiAUNrcMeta } from "/home/node/apps/claimant-portal-ui/pages/auth/index.vue?macro=true";
import { default as blankPqjXqwCg9QMeta } from "/home/node/apps/claimant-portal-ui/pages/blank.vue?macro=true";
import { default as completed_45actionsGQqVCKzlfdMeta } from "/home/node/apps/claimant-portal-ui/pages/completed-actions.vue?macro=true";
import { default as demoKQHn1wyBdHMeta } from "/home/node/apps/claimant-portal-ui/pages/demo.vue?macro=true";
import { default as AccessDeniedMi9ag7awtEMeta } from "/home/node/apps/claimant-portal-ui/pages/error/AccessDenied.vue?macro=true";
import { default as LoginFailedkL8Q2iWK60Meta } from "/home/node/apps/claimant-portal-ui/pages/error/LoginFailed.vue?macro=true";
import { default as NoUserFoundDbpkkhItf2Meta } from "/home/node/apps/claimant-portal-ui/pages/error/NoUserFound.vue?macro=true";
import { default as UserDisabledYCaYRyyXNsMeta } from "/home/node/apps/claimant-portal-ui/pages/error/UserDisabled.vue?macro=true";
import { default as WrongAuthProviderQpt1gnf06GMeta } from "/home/node/apps/claimant-portal-ui/pages/error/WrongAuthProvider.vue?macro=true";
import { default as _91errorCode_93euXH1ZQqffMeta } from "/home/node/apps/claimant-portal-ui/pages/error/[errorCode].vue?macro=true";
import { default as expired_45tokenkV7BgaleMZMeta } from "/home/node/apps/claimant-portal-ui/pages/error/expired-token.vue?macro=true";
import { default as forgot_45passwordLFbnfxI74kMeta } from "/home/node/apps/claimant-portal-ui/pages/forgot-password.vue?macro=true";
import { default as helptq92UC0jNZMeta } from "/home/node/apps/claimant-portal-ui/pages/help.vue?macro=true";
import { default as index46qPLH4OqNMeta } from "/home/node/apps/claimant-portal-ui/pages/index.vue?macro=true";
import { default as _91token_93h0pAixTJGuMeta } from "/home/node/apps/claimant-portal-ui/pages/invite/[token].vue?macro=true";
import { default as _91token_93Gbx0Sc3WKrMeta } from "/home/node/apps/claimant-portal-ui/pages/login/[token].vue?macro=true";
import { default as indexZYVDmUCgZnMeta } from "/home/node/apps/claimant-portal-ui/pages/login/index.vue?macro=true";
import { default as one_45time_45linkuauQ4sgTQ0Meta } from "/home/node/apps/claimant-portal-ui/pages/login/one-time-link.vue?macro=true";
import { default as passwordTNJkgvy1u5Meta } from "/home/node/apps/claimant-portal-ui/pages/login/password.vue?macro=true";
import { default as _91token_93keVpzWwxKLMeta } from "/home/node/apps/claimant-portal-ui/pages/password-reset/[token].vue?macro=true";
import { default as _91unauthenticatedToken_93ZXoUDr6JHtMeta } from "/home/node/apps/claimant-portal-ui/pages/public/actions/[unauthenticatedToken].vue?macro=true";
import { default as indexKsxcf7J22tMeta } from "/home/node/apps/claimant-portal-ui/pages/signup/index.vue?macro=true";
import { default as passwordkOqbl3coWyMeta } from "/home/node/apps/claimant-portal-ui/pages/signup/password.vue?macro=true";
import { default as _91actionId_93LxLUkdpXvLMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/actions/[actionId].vue?macro=true";
import { default as AccessDeniedX1a0r5pRfTMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/error/AccessDenied.vue?macro=true";
import { default as ExpiredLinkCuACJ4yx6fMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/error/ExpiredLink.vue?macro=true";
import { default as InternalErrorH14MAzfIgaMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/error/InternalError.vue?macro=true";
import { default as NoUserFoundibNkV5qOl4Meta } from "/home/node/apps/claimant-portal-ui/pages/v2/error/NoUserFound.vue?macro=true";
import { default as PageNotFoundvvFXGzkhbmMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/error/PageNotFound.vue?macro=true";
import { default as WrongAuthProviderrytAtdURFaMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/error/WrongAuthProvider.vue?macro=true";
import { default as _91errorCode_93FEsJifKq4HMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/error/[errorCode].vue?macro=true";
import { default as indexieFQydyOtmMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/files/[actionId]/direct-deposit/index.vue?macro=true";
import { default as _91fileId_93PDrWcyldS3Meta } from "/home/node/apps/claimant-portal-ui/pages/v2/files/[actionId]/file/[fileId].vue?macro=true";
import { default as _91actionId_93IonNHhGRCLMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/files/[actionId].vue?macro=true";
import { default as indexfqOAEoY1jVMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/files/index.vue?macro=true";
import { default as forgot_45passwordpHS6QQmk7KMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/forgot-password.vue?macro=true";
import { default as indexa0fgcF0CTUMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/help/index.vue?macro=true";
import { default as indexLGBTKps3bVMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/index.vue?macro=true";
import { default as _91token_93XTGNnyYiu5Meta } from "/home/node/apps/claimant-portal-ui/pages/v2/invite/[token].vue?macro=true";
import { default as _91token_93h2rsXMgV4nMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/login/[token].vue?macro=true";
import { default as index5VtmOPpHDJMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/login/index.vue?macro=true";
import { default as one_45time_45linkirESJpXMgvMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/login/one-time-link.vue?macro=true";
import { default as _91token_93dbGSatQ1uiMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/password-reset/[token].vue?macro=true";
import { default as indexWfhwrIeEYXMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/password-reset/index.vue?macro=true";
import { default as indexugTgc3NbAPMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/profile/index.vue?macro=true";
import { default as _91unauthenticatedToken_93NXE0ZqcWXXMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/public/actions/[unauthenticatedToken].vue?macro=true";
import { default as indexIZfpdbhNtUMeta } from "/home/node/apps/claimant-portal-ui/pages/v2/signup/index.vue?macro=true";
export default [
  {
    name: _91actionId_93t1PRQN0HNEMeta?.name ?? "actions-actionId",
    path: _91actionId_93t1PRQN0HNEMeta?.path ?? "/actions/:actionId()",
    meta: _91actionId_93t1PRQN0HNEMeta || {},
    alias: _91actionId_93t1PRQN0HNEMeta?.alias || [],
    redirect: _91actionId_93t1PRQN0HNEMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/actions/[actionId].vue").then(m => m.default || m)
  },
  {
    name: indexEQsLiAUNrcMeta?.name ?? "auth",
    path: indexEQsLiAUNrcMeta?.path ?? "/auth",
    meta: indexEQsLiAUNrcMeta || {},
    alias: indexEQsLiAUNrcMeta?.alias || [],
    redirect: indexEQsLiAUNrcMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: blankPqjXqwCg9QMeta?.name ?? "blank",
    path: blankPqjXqwCg9QMeta?.path ?? "/blank",
    meta: blankPqjXqwCg9QMeta || {},
    alias: blankPqjXqwCg9QMeta?.alias || [],
    redirect: blankPqjXqwCg9QMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: completed_45actionsGQqVCKzlfdMeta?.name ?? "completed-actions",
    path: completed_45actionsGQqVCKzlfdMeta?.path ?? "/completed-actions",
    meta: completed_45actionsGQqVCKzlfdMeta || {},
    alias: completed_45actionsGQqVCKzlfdMeta?.alias || [],
    redirect: completed_45actionsGQqVCKzlfdMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/completed-actions.vue").then(m => m.default || m)
  },
  {
    name: demoKQHn1wyBdHMeta?.name ?? "demo",
    path: demoKQHn1wyBdHMeta?.path ?? "/demo",
    meta: demoKQHn1wyBdHMeta || {},
    alias: demoKQHn1wyBdHMeta?.alias || [],
    redirect: demoKQHn1wyBdHMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: AccessDeniedMi9ag7awtEMeta?.name ?? "error-AccessDenied",
    path: AccessDeniedMi9ag7awtEMeta?.path ?? "/error/AccessDenied",
    meta: AccessDeniedMi9ag7awtEMeta || {},
    alias: AccessDeniedMi9ag7awtEMeta?.alias || [],
    redirect: AccessDeniedMi9ag7awtEMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/error/AccessDenied.vue").then(m => m.default || m)
  },
  {
    name: LoginFailedkL8Q2iWK60Meta?.name ?? "error-LoginFailed",
    path: LoginFailedkL8Q2iWK60Meta?.path ?? "/error/LoginFailed",
    meta: LoginFailedkL8Q2iWK60Meta || {},
    alias: LoginFailedkL8Q2iWK60Meta?.alias || [],
    redirect: LoginFailedkL8Q2iWK60Meta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/error/LoginFailed.vue").then(m => m.default || m)
  },
  {
    name: NoUserFoundDbpkkhItf2Meta?.name ?? "error-NoUserFound",
    path: NoUserFoundDbpkkhItf2Meta?.path ?? "/error/NoUserFound",
    meta: NoUserFoundDbpkkhItf2Meta || {},
    alias: NoUserFoundDbpkkhItf2Meta?.alias || [],
    redirect: NoUserFoundDbpkkhItf2Meta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/error/NoUserFound.vue").then(m => m.default || m)
  },
  {
    name: UserDisabledYCaYRyyXNsMeta?.name ?? "error-UserDisabled",
    path: UserDisabledYCaYRyyXNsMeta?.path ?? "/error/UserDisabled",
    meta: UserDisabledYCaYRyyXNsMeta || {},
    alias: UserDisabledYCaYRyyXNsMeta?.alias || [],
    redirect: UserDisabledYCaYRyyXNsMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/error/UserDisabled.vue").then(m => m.default || m)
  },
  {
    name: WrongAuthProviderQpt1gnf06GMeta?.name ?? "error-WrongAuthProvider",
    path: WrongAuthProviderQpt1gnf06GMeta?.path ?? "/error/WrongAuthProvider",
    meta: WrongAuthProviderQpt1gnf06GMeta || {},
    alias: WrongAuthProviderQpt1gnf06GMeta?.alias || [],
    redirect: WrongAuthProviderQpt1gnf06GMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/error/WrongAuthProvider.vue").then(m => m.default || m)
  },
  {
    name: _91errorCode_93euXH1ZQqffMeta?.name ?? "error-errorCode",
    path: _91errorCode_93euXH1ZQqffMeta?.path ?? "/error/:errorCode()",
    meta: _91errorCode_93euXH1ZQqffMeta || {},
    alias: _91errorCode_93euXH1ZQqffMeta?.alias || [],
    redirect: _91errorCode_93euXH1ZQqffMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/error/[errorCode].vue").then(m => m.default || m)
  },
  {
    name: expired_45tokenkV7BgaleMZMeta?.name ?? "error-expired-token",
    path: expired_45tokenkV7BgaleMZMeta?.path ?? "/error/expired-token",
    meta: expired_45tokenkV7BgaleMZMeta || {},
    alias: expired_45tokenkV7BgaleMZMeta?.alias || [],
    redirect: expired_45tokenkV7BgaleMZMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/error/expired-token.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordLFbnfxI74kMeta?.name ?? "forgot-password",
    path: forgot_45passwordLFbnfxI74kMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordLFbnfxI74kMeta || {},
    alias: forgot_45passwordLFbnfxI74kMeta?.alias || [],
    redirect: forgot_45passwordLFbnfxI74kMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: helptq92UC0jNZMeta?.name ?? "help",
    path: helptq92UC0jNZMeta?.path ?? "/help",
    meta: helptq92UC0jNZMeta || {},
    alias: helptq92UC0jNZMeta?.alias || [],
    redirect: helptq92UC0jNZMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/help.vue").then(m => m.default || m)
  },
  {
    name: index46qPLH4OqNMeta?.name ?? "index",
    path: index46qPLH4OqNMeta?.path ?? "/",
    meta: index46qPLH4OqNMeta || {},
    alias: index46qPLH4OqNMeta?.alias || [],
    redirect: index46qPLH4OqNMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93h0pAixTJGuMeta?.name ?? "invite-token",
    path: _91token_93h0pAixTJGuMeta?.path ?? "/invite/:token()",
    meta: _91token_93h0pAixTJGuMeta || {},
    alias: _91token_93h0pAixTJGuMeta?.alias || [],
    redirect: _91token_93h0pAixTJGuMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/invite/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Gbx0Sc3WKrMeta?.name ?? "login-token",
    path: _91token_93Gbx0Sc3WKrMeta?.path ?? "/login/:token()",
    meta: _91token_93Gbx0Sc3WKrMeta || {},
    alias: _91token_93Gbx0Sc3WKrMeta?.alias || [],
    redirect: _91token_93Gbx0Sc3WKrMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/login/[token].vue").then(m => m.default || m)
  },
  {
    name: indexZYVDmUCgZnMeta?.name ?? "login",
    path: indexZYVDmUCgZnMeta?.path ?? "/login",
    meta: indexZYVDmUCgZnMeta || {},
    alias: indexZYVDmUCgZnMeta?.alias || [],
    redirect: indexZYVDmUCgZnMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: one_45time_45linkuauQ4sgTQ0Meta?.name ?? "login-one-time-link",
    path: one_45time_45linkuauQ4sgTQ0Meta?.path ?? "/login/one-time-link",
    meta: one_45time_45linkuauQ4sgTQ0Meta || {},
    alias: one_45time_45linkuauQ4sgTQ0Meta?.alias || [],
    redirect: one_45time_45linkuauQ4sgTQ0Meta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/login/one-time-link.vue").then(m => m.default || m)
  },
  {
    name: passwordTNJkgvy1u5Meta?.name ?? "login-password",
    path: passwordTNJkgvy1u5Meta?.path ?? "/login/password",
    meta: passwordTNJkgvy1u5Meta || {},
    alias: passwordTNJkgvy1u5Meta?.alias || [],
    redirect: passwordTNJkgvy1u5Meta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/login/password.vue").then(m => m.default || m)
  },
  {
    name: _91token_93keVpzWwxKLMeta?.name ?? "password-reset-token",
    path: _91token_93keVpzWwxKLMeta?.path ?? "/password-reset/:token()",
    meta: _91token_93keVpzWwxKLMeta || {},
    alias: _91token_93keVpzWwxKLMeta?.alias || [],
    redirect: _91token_93keVpzWwxKLMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: _91unauthenticatedToken_93ZXoUDr6JHtMeta?.name ?? "public-actions-unauthenticatedToken",
    path: _91unauthenticatedToken_93ZXoUDr6JHtMeta?.path ?? "/public/actions/:unauthenticatedToken()",
    meta: _91unauthenticatedToken_93ZXoUDr6JHtMeta || {},
    alias: _91unauthenticatedToken_93ZXoUDr6JHtMeta?.alias || [],
    redirect: _91unauthenticatedToken_93ZXoUDr6JHtMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/public/actions/[unauthenticatedToken].vue").then(m => m.default || m)
  },
  {
    name: indexKsxcf7J22tMeta?.name ?? "signup",
    path: indexKsxcf7J22tMeta?.path ?? "/signup",
    meta: indexKsxcf7J22tMeta || {},
    alias: indexKsxcf7J22tMeta?.alias || [],
    redirect: indexKsxcf7J22tMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: passwordkOqbl3coWyMeta?.name ?? "signup-password",
    path: passwordkOqbl3coWyMeta?.path ?? "/signup/password",
    meta: passwordkOqbl3coWyMeta || {},
    alias: passwordkOqbl3coWyMeta?.alias || [],
    redirect: passwordkOqbl3coWyMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/signup/password.vue").then(m => m.default || m)
  },
  {
    name: _91actionId_93LxLUkdpXvLMeta?.name ?? "v2-actions-actionId",
    path: _91actionId_93LxLUkdpXvLMeta?.path ?? "/v2/actions/:actionId()",
    meta: _91actionId_93LxLUkdpXvLMeta || {},
    alias: _91actionId_93LxLUkdpXvLMeta?.alias || [],
    redirect: _91actionId_93LxLUkdpXvLMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/actions/[actionId].vue").then(m => m.default || m)
  },
  {
    name: AccessDeniedX1a0r5pRfTMeta?.name ?? "v2-error-AccessDenied",
    path: AccessDeniedX1a0r5pRfTMeta?.path ?? "/v2/error/AccessDenied",
    meta: AccessDeniedX1a0r5pRfTMeta || {},
    alias: AccessDeniedX1a0r5pRfTMeta?.alias || [],
    redirect: AccessDeniedX1a0r5pRfTMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/error/AccessDenied.vue").then(m => m.default || m)
  },
  {
    name: ExpiredLinkCuACJ4yx6fMeta?.name ?? "v2-error-ExpiredLink",
    path: ExpiredLinkCuACJ4yx6fMeta?.path ?? "/v2/error/ExpiredLink",
    meta: ExpiredLinkCuACJ4yx6fMeta || {},
    alias: ExpiredLinkCuACJ4yx6fMeta?.alias || [],
    redirect: ExpiredLinkCuACJ4yx6fMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/error/ExpiredLink.vue").then(m => m.default || m)
  },
  {
    name: InternalErrorH14MAzfIgaMeta?.name ?? "v2-error-InternalError",
    path: InternalErrorH14MAzfIgaMeta?.path ?? "/v2/error/InternalError",
    meta: InternalErrorH14MAzfIgaMeta || {},
    alias: InternalErrorH14MAzfIgaMeta?.alias || [],
    redirect: InternalErrorH14MAzfIgaMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/error/InternalError.vue").then(m => m.default || m)
  },
  {
    name: NoUserFoundibNkV5qOl4Meta?.name ?? "v2-error-NoUserFound",
    path: NoUserFoundibNkV5qOl4Meta?.path ?? "/v2/error/NoUserFound",
    meta: NoUserFoundibNkV5qOl4Meta || {},
    alias: NoUserFoundibNkV5qOl4Meta?.alias || [],
    redirect: NoUserFoundibNkV5qOl4Meta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/error/NoUserFound.vue").then(m => m.default || m)
  },
  {
    name: PageNotFoundvvFXGzkhbmMeta?.name ?? "v2-error-PageNotFound",
    path: PageNotFoundvvFXGzkhbmMeta?.path ?? "/v2/error/PageNotFound",
    meta: PageNotFoundvvFXGzkhbmMeta || {},
    alias: PageNotFoundvvFXGzkhbmMeta?.alias || [],
    redirect: PageNotFoundvvFXGzkhbmMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/error/PageNotFound.vue").then(m => m.default || m)
  },
  {
    name: WrongAuthProviderrytAtdURFaMeta?.name ?? "v2-error-WrongAuthProvider",
    path: WrongAuthProviderrytAtdURFaMeta?.path ?? "/v2/error/WrongAuthProvider",
    meta: WrongAuthProviderrytAtdURFaMeta || {},
    alias: WrongAuthProviderrytAtdURFaMeta?.alias || [],
    redirect: WrongAuthProviderrytAtdURFaMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/error/WrongAuthProvider.vue").then(m => m.default || m)
  },
  {
    name: _91errorCode_93FEsJifKq4HMeta?.name ?? "v2-error-errorCode",
    path: _91errorCode_93FEsJifKq4HMeta?.path ?? "/v2/error/:errorCode()",
    meta: _91errorCode_93FEsJifKq4HMeta || {},
    alias: _91errorCode_93FEsJifKq4HMeta?.alias || [],
    redirect: _91errorCode_93FEsJifKq4HMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/error/[errorCode].vue").then(m => m.default || m)
  },
  {
    name: _91actionId_93IonNHhGRCLMeta?.name ?? "v2-files-actionId",
    path: _91actionId_93IonNHhGRCLMeta?.path ?? "/v2/files/:actionId()",
    children: [
  {
    name: indexieFQydyOtmMeta?.name ?? "v2-files-actionId-direct-deposit",
    path: indexieFQydyOtmMeta?.path ?? "direct-deposit",
    meta: indexieFQydyOtmMeta || {},
    alias: indexieFQydyOtmMeta?.alias || [],
    redirect: indexieFQydyOtmMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/files/[actionId]/direct-deposit/index.vue").then(m => m.default || m)
  },
  {
    name: _91fileId_93PDrWcyldS3Meta?.name ?? "v2-files-actionId-file-fileId",
    path: _91fileId_93PDrWcyldS3Meta?.path ?? "file/:fileId()",
    meta: _91fileId_93PDrWcyldS3Meta || {},
    alias: _91fileId_93PDrWcyldS3Meta?.alias || [],
    redirect: _91fileId_93PDrWcyldS3Meta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/files/[actionId]/file/[fileId].vue").then(m => m.default || m)
  }
],
    meta: _91actionId_93IonNHhGRCLMeta || {},
    alias: _91actionId_93IonNHhGRCLMeta?.alias || [],
    redirect: _91actionId_93IonNHhGRCLMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/files/[actionId].vue").then(m => m.default || m)
  },
  {
    name: indexfqOAEoY1jVMeta?.name ?? "v2-files",
    path: indexfqOAEoY1jVMeta?.path ?? "/v2/files",
    meta: indexfqOAEoY1jVMeta || {},
    alias: indexfqOAEoY1jVMeta?.alias || [],
    redirect: indexfqOAEoY1jVMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/files/index.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordpHS6QQmk7KMeta?.name ?? "v2-forgot-password",
    path: forgot_45passwordpHS6QQmk7KMeta?.path ?? "/v2/forgot-password",
    meta: forgot_45passwordpHS6QQmk7KMeta || {},
    alias: forgot_45passwordpHS6QQmk7KMeta?.alias || [],
    redirect: forgot_45passwordpHS6QQmk7KMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexa0fgcF0CTUMeta?.name ?? "v2-help",
    path: indexa0fgcF0CTUMeta?.path ?? "/v2/help",
    meta: indexa0fgcF0CTUMeta || {},
    alias: indexa0fgcF0CTUMeta?.alias || [],
    redirect: indexa0fgcF0CTUMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/help/index.vue").then(m => m.default || m)
  },
  {
    name: indexLGBTKps3bVMeta?.name ?? "v2",
    path: indexLGBTKps3bVMeta?.path ?? "/v2",
    meta: indexLGBTKps3bVMeta || {},
    alias: indexLGBTKps3bVMeta?.alias || [],
    redirect: indexLGBTKps3bVMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93XTGNnyYiu5Meta?.name ?? "v2-invite-token",
    path: _91token_93XTGNnyYiu5Meta?.path ?? "/v2/invite/:token()",
    meta: _91token_93XTGNnyYiu5Meta || {},
    alias: _91token_93XTGNnyYiu5Meta?.alias || [],
    redirect: _91token_93XTGNnyYiu5Meta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/invite/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93h2rsXMgV4nMeta?.name ?? "v2-login-token",
    path: _91token_93h2rsXMgV4nMeta?.path ?? "/v2/login/:token()",
    meta: _91token_93h2rsXMgV4nMeta || {},
    alias: _91token_93h2rsXMgV4nMeta?.alias || [],
    redirect: _91token_93h2rsXMgV4nMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/login/[token].vue").then(m => m.default || m)
  },
  {
    name: index5VtmOPpHDJMeta?.name ?? "v2-login",
    path: index5VtmOPpHDJMeta?.path ?? "/v2/login",
    meta: index5VtmOPpHDJMeta || {},
    alias: index5VtmOPpHDJMeta?.alias || [],
    redirect: index5VtmOPpHDJMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/login/index.vue").then(m => m.default || m)
  },
  {
    name: one_45time_45linkirESJpXMgvMeta?.name ?? "v2-login-one-time-link",
    path: one_45time_45linkirESJpXMgvMeta?.path ?? "/v2/login/one-time-link",
    meta: one_45time_45linkirESJpXMgvMeta || {},
    alias: one_45time_45linkirESJpXMgvMeta?.alias || [],
    redirect: one_45time_45linkirESJpXMgvMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/login/one-time-link.vue").then(m => m.default || m)
  },
  {
    name: _91token_93dbGSatQ1uiMeta?.name ?? "v2-password-reset-token",
    path: _91token_93dbGSatQ1uiMeta?.path ?? "/v2/password-reset/:token()",
    meta: _91token_93dbGSatQ1uiMeta || {},
    alias: _91token_93dbGSatQ1uiMeta?.alias || [],
    redirect: _91token_93dbGSatQ1uiMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: indexWfhwrIeEYXMeta?.name ?? "v2-password-reset",
    path: indexWfhwrIeEYXMeta?.path ?? "/v2/password-reset",
    meta: indexWfhwrIeEYXMeta || {},
    alias: indexWfhwrIeEYXMeta?.alias || [],
    redirect: indexWfhwrIeEYXMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexugTgc3NbAPMeta?.name ?? "v2-profile",
    path: indexugTgc3NbAPMeta?.path ?? "/v2/profile",
    meta: indexugTgc3NbAPMeta || {},
    alias: indexugTgc3NbAPMeta?.alias || [],
    redirect: indexugTgc3NbAPMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91unauthenticatedToken_93NXE0ZqcWXXMeta?.name ?? "v2-public-actions-unauthenticatedToken",
    path: _91unauthenticatedToken_93NXE0ZqcWXXMeta?.path ?? "/v2/public/actions/:unauthenticatedToken()",
    meta: _91unauthenticatedToken_93NXE0ZqcWXXMeta || {},
    alias: _91unauthenticatedToken_93NXE0ZqcWXXMeta?.alias || [],
    redirect: _91unauthenticatedToken_93NXE0ZqcWXXMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/public/actions/[unauthenticatedToken].vue").then(m => m.default || m)
  },
  {
    name: indexIZfpdbhNtUMeta?.name ?? "v2-signup",
    path: indexIZfpdbhNtUMeta?.path ?? "/v2/signup",
    meta: indexIZfpdbhNtUMeta || {},
    alias: indexIZfpdbhNtUMeta?.alias || [],
    redirect: indexIZfpdbhNtUMeta?.redirect || undefined,
    component: () => import("/home/node/apps/claimant-portal-ui/pages/v2/signup/index.vue").then(m => m.default || m)
  }
]