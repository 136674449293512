import { BrandingTheme } from '@torticity/types'

import { themes } from '~/themes'
import { TokenKey } from '~/themes/theme'
import DefaultTheme from '~/themes/violet'

export function useTheme() {
  const theme = useState('theme', () => DefaultTheme)

  function setTheme(key: `${BrandingTheme}`): void {
    const foundTheme = themes.find(t => t.key === key)

    if (foundTheme) {
      theme.value = foundTheme
    }
  }

  function getThemeToken(tokenName: TokenKey): string[] {
    return theme.value?.tokens[tokenName] || []
  }

  function getThemeTokenString(tokenName: TokenKey | TokenKey[]): string {
    if (Array.isArray(tokenName)) {
      return tokenName.map(getThemeTokenString).join(' ')
    }
    return getThemeToken(tokenName)?.join(' ') || ''
  }

  return {
    theme,
    setTheme,
    getThemeToken,
    getThemeTokenString,
  }
}
