import { Preferences } from '@capacitor/preferences'
import { BrandingTheme } from '@torticity/types'

import { DEFAULT_BRANDING, DEFAULT_THEME } from '../config/branding'

export default async function defineNuxtRouteMiddleware() {
  const { $http } = useNuxtApp()

  const brand = useBrand()
  const { setTheme } = useTheme()
  const auth = useAuth()

  const dockitApiUrl = useRuntimeConfig().public.dockitApiUrl
  const cookieSubdomain = useCookie('subdomain')
  const route = useRoute()

  const isNative = useIsNative()

  if (isNative) {
    if (auth?.user) {
      try {
        const leadBranding = await $http.$get(`/v1/branding`)
        brand.value = leadBranding
        setTheme(leadBranding?.theme)

        await Preferences.set({
          key: 'subdomain',
          value: leadBranding?.subdomain,
        })
      } catch (error) {
        console.error('Failed to fetch lead branding:', error)
      }
      return
    }

    try {
      const { value: storedSubdomain } = await Preferences.get({ key: 'subdomain' })

      if (storedSubdomain) {
        try {
          const branding = await $http.$get(
            `${dockitApiUrl}/v1/branding/subdomain/${storedSubdomain}`,
          )
          brand.value = branding
          setTheme(branding?.theme)
          return
        } catch (error) {
          console.error('Failed to fetch subdomain branding:', error)
        }
      }
    } catch (error) {
      console.error('Failed to get subdomain from Preferences:', error)
    }

    // Fallback to default if we couldn't get stored subdomain or fetch branding from lead
    brand.value = DEFAULT_BRANDING
    setTheme(DEFAULT_THEME)
    return
  }

  const subdomain =
    useRuntimeConfig().public.subdomain ||
    cookieSubdomain?.value ||
    route.query?.brand ||
    window?.location?.hostname?.split('.')?.[0] ||
    ''

  try {
    brand.value = await $http.$get(`${dockitApiUrl}/v1/branding/subdomain/${subdomain}`)
    setTheme((brand?.value?.theme as BrandingTheme) || 'violet')
  } catch (error: any) {
    abortNavigation({ statusCode: error.response?._data?.statusCode ?? 500 })
  }

  useHead({
    link: [
      {
        rel: 'apple-touch-icon',
        type: 'image/png',
        sizes: '180x180',
        href: brand.value.faviconUrl + 'apple-touch-icon.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: brand.value.faviconUrl + 'favicon-32x32.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: brand.value.faviconUrl + 'favicon-16x16.png',
      },
      {
        rel: 'manifest',
        href: brand.value.faviconUrl + 'site.webmanifest',
      },
    ],
  })
}
