import plugin_vue3_0f50yYmWFn from "/home/node/apps/claimant-portal-ui/node_modules/.pnpm/@pinia+nuxt@0.4.9_vdxgd4kqc4zn4ct2acysaptxfe/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_36c6PlOrj5 from "/home/node/apps/claimant-portal-ui/node_modules/.pnpm/nuxt@3.5.3_ib6n3u26orgsw3ddu5c2ar5ipm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/node/apps/claimant-portal-ui/.nuxt/components.plugin.mjs";
import unhead_ndqqFjVzrL from "/home/node/apps/claimant-portal-ui/node_modules/.pnpm/nuxt@3.5.3_ib6n3u26orgsw3ddu5c2ar5ipm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DVGb7B6zG2 from "/home/node/apps/claimant-portal-ui/node_modules/.pnpm/nuxt@3.5.3_ib6n3u26orgsw3ddu5c2ar5ipm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_Pgbaebjx6c from "/home/node/apps/claimant-portal-ui/node_modules/.pnpm/nuxt@3.5.3_ib6n3u26orgsw3ddu5c2ar5ipm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import http_plugin_SfyNGJvhOm from "/home/node/apps/claimant-portal-ui/.nuxt/http.plugin.mjs";
import auth_plugin_6DDHMgc34l from "/home/node/apps/claimant-portal-ui/.nuxt/auth.plugin.mjs";
import primevue_plugin_egKpok8Auk from "/home/node/apps/claimant-portal-ui/.nuxt/primevue-plugin.mjs";
import plugin_client_DHXkSt855L from "/home/node/apps/claimant-portal-ui/node_modules/.pnpm/@primevue+nuxt-module@4.1.0_rollup@3.29.4+vue@3.3.11/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_aEL9vYN3fL from "/home/node/apps/claimant-portal-ui/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@3.29.4/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_NJQTeHOvIa from "/home/node/apps/claimant-portal-ui/node_modules/.pnpm/nuxt@3.5.3_ib6n3u26orgsw3ddu5c2ar5ipm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import primevue_toast_R8DqZgkqTE from "/home/node/apps/claimant-portal-ui/plugins/primevue-toast.ts";
import auth_test_vBds7NXiJd from "/home/node/apps/claimant-portal-ui/plugins/test/auth-test.ts";
import clarity_client_3WY6KrZTY9 from "/home/node/apps/claimant-portal-ui/plugins/clarity.client.ts";
import cypress_client_6dhyhH346N from "/home/node/apps/claimant-portal-ui/plugins/cypress.client.ts";
import flagsmith_client_3LfxwogPDd from "/home/node/apps/claimant-portal-ui/plugins/flagsmith.client.ts";
import floating_vue_BiOD74u9sH from "/home/node/apps/claimant-portal-ui/plugins/floating-vue.ts";
import hotjar_client_HJG8no80GI from "/home/node/apps/claimant-portal-ui/plugins/hotjar.client.ts";
import sentry_client_shVUlIjFLk from "/home/node/apps/claimant-portal-ui/plugins/sentry.client.ts";
import vee_validate_KcKlKmvCrJ from "/home/node/apps/claimant-portal-ui/plugins/vee-validate.ts";
import vue_pdf_embed_client_gnZnktPI7p from "/home/node/apps/claimant-portal-ui/plugins/vue-pdf-embed.client.ts";
import vue_query_wrmMkNpEpe from "/home/node/apps/claimant-portal-ui/plugins/vue-query.ts";
import plugin_client_95rvVdWa44 from "/home/node/packages/analytics/plugins/plugin.client.ts";
export default [
  plugin_vue3_0f50yYmWFn,
  revive_payload_client_36c6PlOrj5,
  components_plugin_KR1HBZs4kY,
  unhead_ndqqFjVzrL,
  router_DVGb7B6zG2,
  prefetch_client_Pgbaebjx6c,
  http_plugin_SfyNGJvhOm,
  auth_plugin_6DDHMgc34l,
  primevue_plugin_egKpok8Auk,
  plugin_client_DHXkSt855L,
  plugin_aEL9vYN3fL,
  chunk_reload_client_NJQTeHOvIa,
  primevue_toast_R8DqZgkqTE,
  auth_test_vBds7NXiJd,
  clarity_client_3WY6KrZTY9,
  cypress_client_6dhyhH346N,
  flagsmith_client_3LfxwogPDd,
  floating_vue_BiOD74u9sH,
  hotjar_client_HJG8no80GI,
  sentry_client_shVUlIjFLk,
  vee_validate_KcKlKmvCrJ,
  vue_pdf_embed_client_gnZnktPI7p,
  vue_query_wrmMkNpEpe,
  plugin_client_95rvVdWa44
]