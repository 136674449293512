import { BrandingTheme } from '@torticity/types'

import { Theme } from './theme'

const config: Theme = {
  key: BrandingTheme.ROSE,
  label: 'rose',
  tokens: {
    'primary-button': [
      'tw-bg-rose-600',
      'hover:tw-bg-rose-900',
      'tw-text-white',
      'tw-py-4',
      'tw-px-6',
      'tw-rounded-full',
      'tw-font-bold',
      'tw-text-md',
      'tw-cursor-pointer',
      'disabled:tw-opacity-50',
      'disabled:tw-cursor-not-allowed',
    ],
    'secondary-button': [
      'tw-border-rose-200',
      'tw-bg-rose-100',
      'tw-text-rose-950',
      'hover:tw-bg-rose-300',
      'active:tw-bg-rose-400',
      'tw-cursor-pointer',
    ],
    'tertiary-button': [
      'tw-bg-white',
      'tw-border-2',
      'tw-border-rose-600',
      'tw-text-rose-900',
      'hover:tw-bg-rose-50',
      'active:tw-bg-rose-100',
      'tw-rounded-full',
      'tw-font-semibold',
      'tw-text-md',
      'tw-py-2',
      'tw-px-6',
      'tw-cursor-pointer',
    ],
    'primary-text': ['tw-text-rose-700'],
    'header-nav': ['!tw-bg-rose-800', 'tw-text-white'],
    'header-active-item': [
      'tw-font-semibold',
      'tw-text-rose-800',
      'tw-bg-white',
      'tw-bg-opacity-80',
    ],
    'loading-spinner': ['tw-fill-rose-600'],
    'primary-icon': ['tw-fill-rose-500', 'tw-text-rose-500'],
    'secondary-icon': [
      'tw-text-rose-800',
      'tw-bg-rose-100',
      'tw-flex',
      'tw-flex-shrink-0',
      'tw-content-center',
      'tw-items-center',
      'tw-justify-center',
      'tw-rounded-full',
    ],
    'tertiary-icon': ['tw-fill-rose-800', 'tw-text-rose-700'],
    'auth-footer': ['tw-text-white', '!tw-bg-rose-800'],
    'secondary-text': ['tw-text-rose-500'],
    'linear-gradient': ['tw-to-rose-500', 'tw-from-rose-900'],
    'light-linear-gradient': ['tw-bg-rose-500'],
    'dark-linear-gradient': ['tw-bg-rose-900'],
    'light-background': ['tw-bg-rose-50'],
    'dark-background': ['tw-bg-rose-700'],
    'tracker-dots': ['tw-border-rose-500', 'tw-bg-rose-50'],
    'active-tracker-dot': ['tw-bg-rose-800', 'tw-rounded-full'],
    'action-flow': ['tw-bg-rose-600'],
    'action-flow-button': ['tw-bg-rose-600', 'hover:tw-bg-rose-900'],
    'mobile-tracker-line': ['tw-bg-rose-500'],
    'bottom-nav-active-bar': ['tw-bg-rose-700'],
    'primary-border': ['tw-border-rose-500'],
    'dark-border': ['tw-border-rose-700'],
    'hover-background': ['hover:tw-bg-rose-50'],
    'hover-border': ['hover:tw-border-rose-500'],
  },
}

export default config
