import { configure } from 'vee-validate'
import { setLocale } from 'yup'

export default defineNuxtPlugin(() => {
  configure({
    validateOnBlur: true,
    validateOnChange: false,
    validateOnInput: false,
    validateOnModelUpdate: false,
  })

  setLocale({
    mixed: {
      // eslint-disable-next-line no-template-curly-in-string
      required: '${path} is required',
    },
  })
})
