import debounce from 'lodash/debounce'

export interface ApiObject {
  [index: string]:
    | string
    | number
    | boolean
    | ApiObject
    | (string | number | boolean | ApiObject)[]
    | undefined
}

export interface AnalyticAdapter {
  init: (...args: any[]) => void
  track: (event: string, properties?: Partial<ApiObject>) => void
  identify: (userId: string, traits?: Partial<ApiObject>) => void
  page: (category?: string, name?: string, properties?: Partial<ApiObject>) => void
}

export class AnalyticsService<T extends AnalyticAdapter = AnalyticAdapter> {
  client: T | undefined

  constructor(client: T) {
    this.client = client
    client.init()
  }

  track(event: string, properties?: Partial<ApiObject>) {
    this?.client?.track(event, properties)
  }

  identify(userId: string, traits?: Partial<ApiObject>) {
    this?.client?.identify(userId, traits)
  }

  page(category?: string, name?: string, properties?: Partial<ApiObject>) {
    this?.client?.page(category, name, properties)
  }

  trackCoummunicationTemplateUsage(properties?: {
    elementType: 'link' | 'button' | string
    text?: string
    template_id?: string
    user_id?: string
    lead_id?: string
  }) {
    this.track('ElementClicked', properties)
  }

  trackElementClicked(properties?: {
    elementType: 'link' | 'button' | string
    text?: string
    [key: string]: any
  }) {
    this.track('ElementClicked', properties)
  }

  trackMatterTypeClicked(properties?: { elementType: string; matterType?: string; text?: string }) {
    this.track('ElementClicked', properties)
  }

  trackFieldInput(properties?: { fieldName: string; [key: string]: any }) {
    this.track('FieldInputted', properties)
  }

  trackFieldInputted = debounce(this.trackFieldInput, 500)

  trackFormSubmit(properties?: { formName: string; [key: string]: any }) {
    this.track('FormSubmitted', properties)
  }

  trackErrorReceived(properties?: {
    elementType: string
    errorType: string
    errorMessage: string
    [key: string]: any
  }) {
    this.track('ErrorReceived', properties)
  }
}
