export default defineNuxtRouteMiddleware(() => {
  if (process.server) return

  const existingCookie = useCookie('subdomain')
  if (existingCookie.value) return

  const url = useRequestURL()
  const hostname = url.hostname
  const route = useRoute()

  const subdomain =
    useRuntimeConfig().public.subdomain ||
    route.query?.brand ||
    window?.location?.hostname?.split('.')?.[0] ||
    ''

  let domain = '.torticity.com'

  if (hostname.includes('casewiseapp.com')) {
    domain = '.casewiseapp.com'
  }

  if (hostname.includes('vercel.app')) {
    domain = `${window?.location?.hostname?.split('.')?.[0] ?? ''}.vercel.app`
  }

  if (hostname.includes('localhost')) {
    domain = 'localhost'
  }

  const cookieSubdomain = useCookie('subdomain', {
    domain,
    sameSite: 'lax',
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
  })

  if (subdomain !== 'app') {
    cookieSubdomain.value = subdomain as string

    return
  }
})
