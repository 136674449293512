import { AnalyticAdapter, ApiObject } from '.'

export class RudderStackAnalyticAdapter implements AnalyticAdapter {
  private client: typeof import('rudder-sdk-js')
  private key: string
  private url: string

  constructor(client: typeof import('rudder-sdk-js'), { key, url }: { key: string; url: string }) {
    this.client = client
    this.key = key
    this.url = url
  }

  init() {
    if (!this.key) return
    this.client.load(this.key, this.url)
  }

  track(event: string, properties?: Partial<ApiObject>) {
    if (!this.key) return
    this.client.track(event, properties)
  }

  identify(userId: string, traits?: Partial<ApiObject>) {
    if (!this.key) return
    this.client.identify(userId, traits)
  }

  page(category?: string, name?: string, properties?: Partial<ApiObject>) {
    if (!this.key) return
    this.client.page(category, name, properties)
  }
}
