import { Auth, ExpiredAuthSessionError } from '#auth/runtime'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import { defu } from 'defu';

// Active schemes
import { RefreshScheme } from '#auth/runtime'

export default defineNuxtPlugin(nuxtApp => {
    // Options
    const options = {
  "globalMiddleware": true,
  "enableMiddleware": true,
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": true,
  "redirectStrategy": "storage",
  "routerStrategy": "router",
  "watchLoggedIn": true,
  "redirect": {
    "login": "/login",
    "logout": "/",
    "home": "/",
    "callback": "/login"
  },
  "pinia": {
    "namespace": "auth",
    "persist": false
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/",
      "sameSite": "lax",
      "secure": true,
      "maxAge": 2678400
    }
  },
  "localStorage": {
    "prefix": "auth."
  },
  "sessionStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "refresh"
}

    // Create a new Auth instance
    const auth = new Auth(nuxtApp, options)

    // Register strategies
    auth.registerStrategy('refresh', new RefreshScheme(auth, defu(useRuntimeConfig()?.public?.auth?.strategies?.['refresh'], {
  "name": "refresh",
  "enabled": true,
  "endpoints": {
    "login": {
      "url": "/v1/auth/login",
      "method": "post"
    },
    "logout": {
      "url": "/v1/auth/logout",
      "method": "post"
    },
    "user": {
      "url": "/v1/auth/profile",
      "method": "get"
    },
    "refresh": {
      "url": "/v1/auth/refresh",
      "method": "post"
    }
  },
  "token": {
    "property": "accessToken",
    "maxAge": 900,
    "required": true
  },
  "refreshToken": {
    "property": "refreshToken",
    "data": "refreshToken",
    "maxAge": 43200,
    "required": true
  },
  "user": {
    "property": false,
    "autoFetch": true
  }
})))

    nuxtApp.provide('auth', auth)

    return auth.init().catch(error => {
        if (process.client) {
            // Don't console log expired auth session errors. This error is common, and expected to happen.
            // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
            // token. We don't want to log this as an error.
            if (error instanceof ExpiredAuthSessionError) {
                return
            }

            console.error('[ERROR] [AUTH]', error)
        }
    })
})