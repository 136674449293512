export interface BrandingState {
  id?: string
  lawFirmId?: string
  name?: string
  subdomain?: string
  intakeNumber?: string
  outreachNumber?: string
  supportEmail?: string
  supportFaxNumber?: string
  smsNumber?: string
  address1?: string
  address2?: string
  city?: string
  state?: string
  zipCode?: string
  pngLogoUrl?: string
  svgLogoUrl?: string
  faviconUrl?: string
  brandingExperience?: string
  theme?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  lawFirm?: {
    id?: string
    name?: string
    intakeNumber?: string
    outreachNumber?: string
    dialerList?: string
    shortName?: string
    hasCobrand?: false
    legacyId?: string
    logoUrl?: string
    svgLogoUrl?: string
    crmLink?: string
    supportPhoneNumber?: string
    supportEmail?: string
    forwardingEmail?: string
    faxNumber?: string
    address1?: string
    address2?: string
    city?: string
    state?: string
    zipCode?: string
    subdomain?: string
    favicon?: string
    isExportable?: false
    createdAt?: string
    updatedAt?: string
  }
}

export const useBrand = () => useState<BrandingState>('brand', () => ({}))
