import flagsmith from 'flagsmith-es'
import type { IInitConfig } from 'flagsmith-es/types'

export default defineNuxtPlugin(async nuxtApp => {
  if (nuxtApp?.$config?.public?.appEnv === 'test') {
    // @ts-ignore
    flagsmith.setState(globalThis?.$flagsmith ?? {})
  }

  const config = useRuntimeConfig().public
  const flagsmithConfig: IInitConfig = {
    environmentID: config.flagsmithEnvironmentId as string,
    // flagsmith needs to be given an implementation of fetch to work
    // https://github.com/Flagsmith/flagsmith-js-client/releases/tag/3.5.0
    fetch,
  }

  await flagsmith.init(flagsmithConfig)

  useState('flagsmith', () => flagsmith.getState())

  return {
    provide: {
      flagsmith,
    },
  }
})
