import { NuxtApp } from '#app'
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp: NuxtApp) => {
  const config = useRuntimeConfig()
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentry.dsn,
    environment: config.public.sentry.environment,
    integrations: [],
  })

  return {
    provide: {
      sentry: Sentry,
    },
  }
})

declare module '#app' {
  interface NuxtApp {
    $sentry: typeof Sentry
  }
}

declare module '@vue/runtime-core' {
  interface NuxtApp {
    $sentry: typeof Sentry
  }
}
