import { useNuxtApp } from '#app'
import { AnalyticsService } from '../services/analytics'

declare module '#app' {
  interface NuxtApp {
    $analytics: AnalyticsService
  }
}

export const useAnalytics = () => {
  const nuxtApp = useNuxtApp()
  return nuxtApp.$analytics
}
